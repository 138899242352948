.container {
  height: 100vh;
  padding: 5rem;
  box-sizing: border-box;
}
.vector-graphics {
  filter: drop-shadow(0 40px 10px rgba(0, 0, 0, 0.4));
  animation: vectorAnimation 500ms infinite ease-in-out alternate;
}
@keyframes vectorAnimation {
  to {
    transform: translateY(-10px);
  }
}

.brandsBanner > svg {
  color: white;
  font-size: 3rem;
  transition: color 1s;
}
.brandsBanner > svg:hover {
  color: rgb(255, 211, 0);
}
.container2 {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container2 video {
  border: 1px solid rgba(0, 0, 0, 0.18);
  width: 60%;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.459);
}

@media screen and (max-width: 600px) {
  .container2 {
    height: unset;
    padding: 10vh 0;
  }
  .container2 video {
    width: 100%;
  }
}

.course {
  margin-bottom: 1rem;
  width: 250px;
  transition: all 0.5s;
}
.course:hover {
  transform: translateY(-10px);
}
